import React, { useState } from "react";
import Slider from "./Slider";
import List from "./List";
import Button from "./Button";
import Select from "react-select";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faStar, faHeart } from "@fortawesome/free-solid-svg-icons";
import { current } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { setActiveItem } from "../app/reducers/activePage";

const Header = () => {
  const { currentUser, avatar } = useSelector((state) => state.user);
  console.log(currentUser, "scsadcsdc");
  const [humbugerclass, sethumbergerClass] = useState("humbergerdiv");
  const [reponisveMenu, setResponsiveMenu] = useState(false);
  const activepage = useSelector((state) => state.activepage);
  const dispatch = useDispatch();
  const burgerAnimation = () => {
    if (humbugerclass == "humbergerdiv") {
      sethumbergerClass("cross");
      setResponsiveMenu(true);
    } else {
      sethumbergerClass("humbergerdiv");
      setResponsiveMenu(false);
    }
  };

  const pages = [
    {
      name: "Add property",
      url: "/addproperty",
    },
    {
      name: "All properties",
      url: "/allproperties",
    },
    {
      name: "Blogs",
      url: "/allblogs",
    },
    {
      name: " Add Blogs",
      url: "/addblog",
    },
  ];

  return (
    <div className="z-40 ">
      <div
        className="flex relative w-full justify-between
 items-center lg:justify-between lg:items-center px-2 md:px-8  md:py-4 max-w-[1480px] mx-auto  z-20 bg-[#f5f5f5] py-4"
      >
        <div className="px-2 ">
          <Link to="/">
            <img
              src="/assets/logos/rebhub_main.png"
              alt="rebhub"
              className="w-[90px] md:w-[150px]"
            />
          </Link>
        </div>
        {/* <div
          className={`${humbugerclass} z-[100] md:hidden`}
          onClick={() => burgerAnimation()}
        >
          <svg id="icon" viewBox="0 0 800 600" fill="#">
            <path
              d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
              className="paths "
              id="top"
            ></path>
            <path d="M300,320 L540,320" className="paths" id="middle"></path>
            <path
              d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
              className="paths"
              id="bottom"
              transform="translate(480, 320) scale(1, -1)         translate(-480, -318) "
            ></path>
          </svg>
        </div> */}
        <div className="hidden md:block">{/* <List listItem={pages} /> */}</div>
        <div className=" cursor-pointer flex gap-[6px] justify-center items-center">
          {currentUser ? (
            <Link
              to="/profile"
              className=" cursor-pointer flex gap-[6px] justify-center items-center"
            >
              <img src={avatar} alt="" className="w-[30px] rounded-full" />
              <p className="">{currentUser?.name}</p>
            </Link>
          ) : (
            <Link
              to="/signin"
              className=" cursor-pointer flex gap-[6px] justify-center items-center"
            >
              <FontAwesomeIcon
                icon={faUser}
                size="sm"
                style={{ color: "#1f2937" }}
                className="px-1"
              />
              <p>Sign In</p>
            </Link>
          )}
        </div>
      </div>
      <ul className="flex mx-auto   justify-center items-center  bg-[#333940] gap-[2px] md:gap-4 p-2 ">
        {pages?.map((item, index) => {
          const isActive = activepage === item.name;

          return (
            <Link to={item.url}>
              <li
                key={index}
                className={`cursor-pointer transition-all duration-300 ease-in ${
                  isActive
                    ? "bg-white text-[#363a41]  rounded-[5px]"
                    : "text-white"
                }`}
                onClick={() => {
                  dispatch(setActiveItem(item.name));
                  console.log(item.name);
                }}
              >
                <button className=" text-[13px] md:text-[16px]  cursor-pointer  p-2   px-2    md:px-4 whitespace-nowrap ">
                  {item.name}
                </button>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default Header;

//
//       <div>
//         <div
//           className="flex relative w-full justify-between
//  items-center lg:justify-between lg:items-end  md:px-8 lg:px-16 py- lg:py-4 max-w-[1480px] mx-auto border-b-2 border-[#009932] z-20 bg-white/90 "
//         >
//           <div
//             className={`${humbugerclass} z-[100] md:hidden`}
//             onClick={() => burgerAnimation()}
//           >
//             <svg id="icon" viewBox="0 0 800 600">
//               <path
//                 d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
//                 className="paths "
//                 id="top"
//               ></path>
//               <path d="M300,320 L540,320" className="paths" id="middle"></path>
//               <path
//                 d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
//                 className="paths"
//                 id="bottom"
//                 transform="translate(480, 320) scale(1, -1)         translate(-480, -318) "
//               ></path>
//             </svg>
//           </div>
//           <div className="px-2 ">
//             <img
//               src="/assets/logos/rebhub.png"
//               alt="rebhub"
//               className="w-[70px] md:w-[100px]"
//             />
//           </div>
//           <div className="hidden lg:block">
//             <List listItem={pages} />
//           </div>
//           <div className="md:flex gap-4 hidden">
//             <Button isbg={false} buttonText={"Signin"} />
//             <Button isbg={true} buttonText={"Join"} />
//           </div>
//           <div className="md:hidden">
//             <svg
//               width="25"
//               height="25"
//               viewBox="0 0 20 20"
//               className="flex justify-center items-center"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 fill-rule="evenodd"
//                 clip-rule="evenodd"
//                 d="M4.50655 0C2.01765 0 0 2.02561 0 4.52433C0 7.02304 2.01765 9.04865 4.50655 9.04865C5.58762 9.04865 6.57978 8.66649 7.3563 8.02939L11.1443 11.8519C11.3393 12.0487 11.6564 12.0495 11.8524 11.8537C12.0485 11.6579 12.0493 11.3396 11.8542 11.1427L8.05706 7.31101C8.65605 6.54289 9.0131 5.57547 9.0131 4.52433C9.0131 2.02561 6.99545 0 4.50655 0ZM1.00146 4.52433C1.00146 2.58088 2.57074 1.00541 4.50655 1.00541C6.44236 1.00541 8.01165 2.58088 8.01165 4.52433C8.01165 6.46777 6.44236 8.04325 4.50655 8.04325C2.57074 8.04325 1.00146 6.46777 1.00146 4.52433Z"
//                 fill="#000000"
//               />
//             </svg>
//           </div>
//         </div>
//       </div>

//       <div className="relative    py-[10vh]  z-20 text-shadow px-4 lg:px-0">
//         <div className="bg-white max-w-[600px] p-4 shadow-xl mx-auto lg:mx-0">
//           <p className="text-center text-[25px] md:text-[30px] leading-10   lg:text-[35px] font-bold    drop-shadow-2xl font-[EurostileBold] ">
//             Pakistan Latest And Emerging{" "}
//             <span className="text-[#009932]"> Realestate Bussiness Hub</span>
//           </p>
//           <p className="text-center  text-[16px] md:text-[22px]   lg:text-[25px] pt-4  drop-shadow-xl">
//             Find your new home.
//           </p>
//           <p className="text-center  text-[16px] md:text-[22px]   lg:text-[25px]    ">
//             Search properties for sale and rent
//           </p>
//         </div>
//         <div className="bg-[#009932] p-6 flex flex-wrap lg:flex-nowrap justify-center items-center gap-2 mt-8 lg:mt-20 mx-4 rounded-sm">
//           <p className=" tracking-wide ">vgvgv</p>

//           {/* <Select options={options} className="min-w-[250px] w-full " />
//           <Select options={options} className="min-w-[250px]  w-full" />
//           <Select options={options} className="min-w-[250px]  w-full" />
//           <Button isbg={false} buttonText={"Buy"} bgcolor={"bg-[#ffff00]"} />
//           <Button isbg={false} buttonText={"Rent"} bgcolor={"bg-[#ffff00]"} /> */}
//         </div>
//       </div>

// <div className="absolute  top-0 z-0 w-full">
//   <div className="absolute top-0 z-0 w-full h-full bg-black opacity-60"></div>
//   <img
//     src="/assets/images/villa.jpeg"
//     alt=""
//     className=" min-h-[100vh] max-w-[1480px] mx-auto h-full w-full"
//   />
// </div>
//     </div>
