import React from "react";
// import Home from "../pages/home";
// import Buy from "../pages/buy";
// import Prices from "../pages/prices";
// import Findanagency from "../pages/findanagency";
// import Marketinelligence from "../pages/marketintelligence";
// import Blogs from "../pages/blogs";
// import Rent from "../pages/rent";
// import Newproject from "../pages/newproject";
// import Trends from "../pages/trends";
// import Agencyportal from "../pages/agencyportal";
// import Privacypolicy from "../pages/privacypolicy";
// import About from "../pages/about";
// import Careers from "../pages/careers";
// import Contactus from "../pages/contactus";
// import Agents from "../pages/agents";
import { Routes, Route } from "react-router-dom";
// import Cominsoon from "../components/Cominsoon";
// import Properties from "../pages/properties";
import Signin from "../pages/authentication/Signin";
import Signup from "../pages/authentication/Signup";
import Userprofile from "../pages/userprofile";
import Privateroute from "../components/Privateroute";
import Allproperties from "../pages/allproperties";
import Addproperties from "../pages/addproperties";
import Addblog from "../pages/addblog";
import Allblogs from "../pages/allblogs";
import Listing from "../pages/listing";
const Allroutes = () => {
  return (
    <>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route element={<Privateroute />}>
          <Route path="/profile" element={<Userprofile />} />
          <Route path="/allproperties" element={<Allproperties />} />
          <Route path="/addproperty" element={<Addproperties />} />
          <Route path="/addblog" element={<Addblog />} />
          <Route path="/allblogs" element={<Allblogs />} />
          <Route path="/listing/:listingId" element={<Listing />} />
        </Route>
      </Routes>
    </>
  );
};

export default Allroutes;
