import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDeyhH9EpwmmzW-Dpypns9OsjdDDtJnhRA",
  authDomain: "rebhub-4d507.firebaseapp.com",
  databaseURL: "https://rebhub-4d507-default-rtdb.firebaseio.com",
  projectId: "rebhub-4d507",
  storageBucket: "rebhub-4d507.appspot.com",
  messagingSenderId: "313456806788",
  appId: "1:313456806788:web:5eee0cdd7873f416576bd1",
  measurementId: "G-0FTR66KB9D",
};

export const app = initializeApp(firebaseConfig);
