import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpdateListing from "../components/Updateproperty";
import DeleteListing from "../components/DeleteListing";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import UploadListing from "../components/UploadListing";
const Pagination = ({
  propertiesPerPage,
  totalProperties,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalProperties / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center my-4 ">
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`px-3 py-1 mx-1  text-white rounded-md cursor-pointer ${
            currentPage == number ? "bg-[#2a9932]" : "bg-gray-500"
          }`}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

const Allproperties = () => {
  const [updateproperty, setUpdateproperty] = useState(false);
  const [currentproperty, setCurrentproperty] = useState({});
  const [deleteproperty, setDeleteproperty] = useState(false);
  const [uploadproperty, setUploadproperty] = useState(false);

  const dispatch = useDispatch();
  const properties = useSelector((state) => state.properties.data);
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 5;

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSharebuttonClick = async (lisitngId) => {
    try {
      // Specify the link you want to copy
      const linkToCopy = `https://rebhub-web-client.vercel.app/listing/${lisitngId}`;

      // Copy the link to the clipboard
      await navigator.clipboard.writeText(linkToCopy);

      // Construct the WhatsApp link with the default text
      const whatsappLink = `https://wa.me/?text=${encodeURIComponent(
        linkToCopy
      )}`;

      // Redirect the user to WhatsApp
      window.location.href = whatsappLink;
    } catch (error) {
      console.error("Error copying link to clipboard:", error);
    }
  };

  const handleListingupload = async (listingId) => {
    try {
      const response = await axios.post(
        `https://rebhub-web-server.vercel.app/admin/listing/uploadtoweb/${listingId}`
      );
      const data = await response.data;
      if (data.success === false) {
        console.log(data.message);
        return;
      }
      toast.success("Listing uploaded to web successfully");
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      <div className="m-4 lg:p-8 xl:p-16 overflow-scroll lg:overflow-auto">
        <p className="text-2xl font-semibold py-2">All Properties</p>

        <table className="min-w-[300px] md:min-w-[450px] lg:min-w-[600px] xl:min-w-[900px] bg-white shadow-sm border w-full rounded-lg ">
          <thead className="bg-[#f5f5f5] rounded-lg">
            <tr className="border-b font-normal text-[14px] rounded-lg">
              <th className="font-normal py-2 text-start border-r px-2 lg:px-4">
                S.No
              </th>
              <th className="font-normal py-2 border-r px-2 lg:px-4">Title</th>
              <th className="font-normal py-2 border-r px-2 lg:px-4">Price</th>
              <th className="font-normal py-2 border-r px-2 lg:px-4">Rooms</th>
              <th className="font-normal py-2 border-r px-2 lg:px-4">
                Address
              </th>
              <th className="font-normal py-2 border-r px-2 lg:px-4">Type</th>
              <th className="font-normal py-2 border-r px-2 lg:px-4">
                Actions
              </th>
              {/* <th className="font-normal py-2 border-r px-2 lg:px-4">Delete</th> */}
              <th className="font-normal py-2 border-r px-2 lg:px-4">Share</th>
              <th className="font-normal py-2 px-2 lg:px-4">Web</th>
            </tr>
          </thead>
          <tbody>
            {currentProperties.length > 0 ? (
              <>
                {currentProperties.map((property, index) => (
                  <tr key={index} className="text-start border-b">
                    <td className="py-2 text-[12px] border-r px-2 lg:px-4">
                      {index + 1 + propertiesPerPage * (currentPage - 1)}
                    </td>
                    <td className="py-2 text-[12px] text-start border-r px-2 lg:px-4">
                      {property.name}
                    </td>
                    <td className="py-2 text-[12px] border-r px-2 lg:px-4">
                      {property.regularPrice}
                    </td>
                    <td className="py-2 text-[12px] border-r px-2 lg:px-4">
                      {property.bedrooms}
                    </td>
                    <td className="py-2 text-[12px] border-r px-2 lg:px-4">
                      {property.address}
                    </td>
                    <td className="py-2 text-[12px] border-r px-2 lg:px-4">
                      {property.type}
                    </td>
                    <td className="py-2 text-[12px] text-center border-r px-2 lg:px-4 cursor-pointer ">
                      <Link to={`/listing/${property._id}`}>
                        {" "}
                        <i class="fas fa-eye"></i>
                      </Link>
                      <i
                        className="fas fa-edit px-3"
                        style={{ color: "blue" }}
                        onClick={() => {
                          setUpdateproperty(true);
                          setCurrentproperty(property);
                        }}
                      ></i>

                      <i
                        className="fas fa-trash"
                        style={{ color: "red" }}
                        onClick={() => {
                          setDeleteproperty(true);
                          setCurrentproperty(property);
                        }}
                      ></i>
                    </td>

                    <td className="py-2 text-[12px] border-r px-2 lg:px-4 ">
                      <p
                        className=" bg-[rgb(246,153,22)] text-white px-2 py-2 rounded-md text-center cursor-pointer"
                        onClick={() => handleSharebuttonClick(property._id)}
                      >
                        <i class="fas fa-share-square  px-[2px]"></i>
                        share
                      </p>
                    </td>
                    <td className="py-2 text-[12px] border-r px-2 lg:px-4 ">
                      {property?.uploadStatus ? (
                        <p className=" bg-green-500 text-white px-2 py-2 text-center rounded-md cursor-not-allowed">
                          uploaded
                        </p>
                      ) : (
                        <p
                          className=" bg-[#3daaf8] text-white px-2 py-2 text-center rounded-md cursor-pointer"
                          // onClick={() => handleListingupload(property._id)}
                          onClick={() => {
                            setUploadproperty(true);
                            setCurrentproperty(property);
                          }}
                        >
                          <i class="fas fa-upload  px-[2px]"></i> upload
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <p className="py-2 text-center">No properties added yet</p>
            )}
          </tbody>
        </table>

        <Pagination
          propertiesPerPage={propertiesPerPage}
          totalProperties={properties.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
      {updateproperty && (
        <div className="absolute w-full top-4 bg-black/40 mx-auto">
          <div className="w-full mx-auto  h-full  mt-24 ">
            <UpdateListing
              setUpdateproperty={setUpdateproperty}
              currentproperty={currentproperty}
            />
          </div>
        </div>
      )}
      {deleteproperty && (
        <div className="absolute w-full top-4 bg-black/40 mx-auto min-h-[80vh]">
          <div className="w-full mx-auto  h-full  mt-24 ">
            <DeleteListing
              listingId={currentproperty._id}
              setDeleteproperty={setDeleteproperty}
            />
          </div>
        </div>
      )}
      {uploadproperty && (
        <div className="absolute w-full top-4 bg-black/40 mx-auto min-h-[80vh]">
          <div className="w-full mx-auto  h-full  mt-24 ">
            <UploadListing
              listingId={currentproperty._id}
              setUploadproperty={setUploadproperty}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Allproperties;
