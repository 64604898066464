// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createImageFromInitials } from "../../helpers/common/createImage";
import { getRandomColor } from "../../helpers/common/getrandomcolor";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  avatar: null,
  error: null,
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signInStart: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.currentUser = action.payload;
      state.avatar = createImageFromInitials(
        500,
        action.payload.name,
        getRandomColor()
      );
      state.loading = false;
      state.error = null;
    },
    signInFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateUserStart: (state) => {
      state.loading = true;
    },
    updateUserSuccess: (state, action) => {
      state.currentUser = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateUserFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteUserStart: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
    },
    deleteUserFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    signOutUserStart: (state) => {
      state.loading = true;
    },
    signOutUserSuccess: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
    },
    signOutUserFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFailure,
  updateUserFailure,
  updateUserSuccess,
  updateUserStart,
  deleteUserFailure,
  deleteUserSuccess,
  deleteUserStart,
  signOutUserFailure,
  signOutUserSuccess,
  signOutUserStart,
} = userSlice.actions;

export default userSlice.reducer;

// const initialState = {
//   currentUser: null,
//   avatar: null,
//   error: null,
//   loading: false,
// };

// const userSlice = createSlice({
//   name: "user",
//   initialState,
//   reducers: {
//     signInStart: (state) => {
//       state.loading = true;
//     },
//     signInSuccess: (state, action) => {
//       state.currentUser = action.payload;
//       state.avatar = createImageFromInitials(
//         500,
//         action.payload.name,
//         getRandomColor()
//       );
//       state.loading = false;
//       state.error = null;
//     },
//     signInFailure: (state, action) => {
//       state.error = action.payload;
//       state.loading = false;
//     },
//   },
// });

// export const { signInStart, signInSuccess, signInFailure } = userSlice.actions;
// export default userSlice.reducer;
