import { current } from "@reduxjs/toolkit";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  signOutUserStart,
} from "../../app/reducers/userReducer";
import { useDispatch } from "react-redux";

const Index = () => {
  const dispatch = useDispatch();
  const { avatar, currentUser } = useSelector((state) => state.user);
  const fileRef = useRef(null);

  const handleSignOut = async () => {
    try {
      dispatch(signOutUserStart());
      const response = await axios.get(
        "http://localhost:4000/admin/auth/signout"
      );
      const data = response.data;

      if (data.success === false) {
        dispatch(deleteUserFailure(data.message));
        return;
      }
      dispatch(deleteUserSuccess(data));
    } catch (error) {
      dispatch(deleteUserFailure(error.message));
    }
  };

  return (
    <div className="max-w-[500px] mx-auto px-2">
      <h1 className="text-3xl font-semibold text-center my-7">Profile</h1>
      <form
        action="
      "
        className="flex flex-col gap-4 max-w-[500px] justify-center items-center w-full mx-auto"
      >
        <input type="file" ref={fileRef} hidden accept="image/*" />
        <img
          src={avatar}
          alt="Profile"
          className="rounded-full h-24 w-24 object-cover cursor-pointer self-center mt-2 "
          onClick={() => fileRef.current.click()}
        />
        <input
          type="text"
          placeholder="Name"
          id="username"
          value={currentUser?.name}
          className="p-3 border rounded-lg w-full"
        />

        <input
          type="email"
          placeholder="Email"
          id="email"
          value={currentUser?.email}
          className="p-3 border rounded-lg w-full"
        />
        {/* <input
          type="text"
          placeholder="Name"
          className="p-3 border rounded-lg w-full"
        /> */}
        <button
          disabled
          className="bg-[#2a9933]  text-white rounded-lg p-3 uppercase hover:opacity-90 disabled:opacity-80 w-full"
        >
          update
        </button>
      </form>
      <div className="flex justify-between mt-5">
        <span className="text-red-700 cursor-pointer">Delete Account</span>
        <span className="text-red-700 cursor-pointer" onClick={handleSignOut}>
          Signout
        </span>
      </div>
    </div>
  );
};
export default Index;
