import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
const DeleteListing = ({ listingId, setDeleteproperty }) => {
  const { currentUser, loading, error } = useSelector((state) => state.user);
  const [username, setUsername] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  function handleName(e) {
    setIsButtonDisabled(false);
    setUsername(e.target.value);
    if (e.target.value == currentUser.name) {
      setIsButtonDisabled(true);
    }
  }

  console.log(listingId, "listingId");
  const handleListingDelete = async () => {
    console.log("delete called");
    try {
      const response = await axios.delete(
        `https://rebhub-web-server.vercel.app/admin/listing/delete/${listingId}`
      );

      const data = await response.data;
      if (data.success === false) {
        console.log(data.message);
        return;
      }
      setDeleteproperty(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="mt-20  mx-auto max-w-[400px] bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center py-4">
        <p className="text-black font-bold text-2xl ">DeleteListing</p>
        <p
          className="text-black font-bold text-2xl  cursor-pointer"
          onClick={() => setDeleteproperty(false)}
        >
          X
        </p>
      </div>
      <div className="flex flex-col">
        <label htmlFor="name">
          Enter your name " {currentUser.name} " to confirm Deletion:
          <input
            type="text"
            value={username}
            id="name"
            onChange={handleName}
            className="bg-white border-2 border-gray-300 focus:outline-none rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          />
        </label>

        {/* <p>{currentUser.name}fsdf</p> */}
        <button
          disabled={!isButtonDisabled}
          className={`p-3  ${
            isButtonDisabled ? "bg-red-600" : "bg-red-200"
          } rounded uppercase hover:shadow-lg disabled:opacity-80 text-white my-4`}
          onClick={handleListingDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteListing;
