import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { BrowserRouter } from "react-router-dom";
import Allroutes from "./Allroutes";
import axios from "axios";
import Topbar from "../components/Topbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Index = () => {
  return (
    <BrowserRouter>
      <div className="z-40 " style={{ zIndex: 2 }}>
        {/* <Topbar /> */}
        <Header />
      </div>
      <Allroutes />
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Index;
