import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// const data = [
//   {
//     property_id: "5",
//     society_name: "DHA",
//     image: "./assets/images/1.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 U Block",
//     title:
//       "One Kanal Brand New Spanish Design House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 92500000,
//     keywords: ["family home", "garden", "residential", "charming"],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "3",
//     society_name: "DHA",
//     image: "./assets/images/2.jpeg",
//     property_type: "House",
//     area: "5 marla",
//     location: "DHA 9 Town B Block",
//     title:
//       "Defence 5 Marla Brand New Stylish Luxurious Bungalow Available For Sale",
//     description:
//       "Defence 5 Marla Brand New Stylish Luxurious Bungalow\n3 Bed Rooms with attached bath\nAll baths are equipped with imported fittings\nFall Ceiling done all over including bedrooms/drawing/dinning and kitchen\nComplete marble/tiled/wooden flooring\nWashrooms are completely covered by Tiles\nAll the construction near and adjacent to the house is new\nLights provision in all cabinets of kitchen\nBeautiful combination of tiles in drawing and dining Opportunity To Enjoy Large Terrace In 5 Marla House. It Can Be Used For Multi Purposes Like Walking, Sitting, Relaxing, Partying Etc. Beautiful Elevation With The Mix Of Tile, Marble And Weather Sheet. Powder Room",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 3,
//     bathrooms: 4,
//     price: 27500000,
//     keywords: ["charming", "family home", "garden", "residential"],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 3",
//         "Bathrooms: 4",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "4",
//     society_name: "DHA",
//     image: "./assets/images/3.jpeg",
//     property_type: "House",
//     area: "10 marla",
//     location: "DHA Phase 7 Y Block",
//     title: "Unique Designed 10 Marla Brand New Luxury House Ready For Sale",
//     description:
//       "04 Master Size Bed Rooms With Attached Bath\n1 Huge Size TV Lounge\n2 Kitchens\nHuge Parking Space\nAll Baths Are Equipped With Imported Fittings\nHouse Is Located Near Park Mosque\nCommercial Area Is Very Near To Premises\nTotal Covered Area Of House Is 3000 Sq Ft\nDouble Story House\nComplete Wiring Of UPS And Generator Done In The House\nSolid Steel Work Used For Outside Main Gate\nComplete Marble Tiled Wooden Flooring\nWash Rooms Are Completely Covered By Spanish Tiles\n50 Ft Road\nAll The Construction Near And Adjacent To The House Is New\nSpacious Green Belt\nSide Streets Are Proper Covered With Tiles\nHouse Is Completed According To DHA By-Laws\nSolid Steel Work Used In Cabinets\nFall Ceiling Done All Over Including Bed Rooms Drawing Dinning And\nKitchen\nSeepage Protection Given While Constructing The Home\nProtection Sheets Installed While Building The Upper Portion To Keep Both Portions Cool In Summers\nContact For Further Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 4,
//     bathrooms: 5,
//     price: 50000000,
//     keywords: ["charming", "family home", "garden", "residential"],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 4",
//         "Bathrooms: 5",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/4.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/5.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/6.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/5.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/6.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/7.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
//   {
//     property_id: "6",
//     society_name: "DHA",
//     image: "./assets/images/8.jpeg",
//     property_type: "House",
//     area: "One Kanal 4500sf",
//     location: "DHA Phase 7 T Block",
//     title:
//       "Brand New Spanish Design Sami Furnished One Kanal House For Sale At Prime Location Of DHA Phase 7",
//     description:
//       "5 Master Bed With Attached Baths\n6 Bathrooms In The Bungalow.\n2 Beautiful TV Lounge, 1 Drawing & Dining\n3 Fotile Company Kitchens\n2 Servant Quarters\n4 Big Cars Parking\nGrohi Sanitary Fittings\n2 Store Rooms\nBeautiful Lawn & Terrace\nBar B.Q Area In Bungalow\nAsh Wood Door Almirah\nHouse Is Located Near Park Mosque.\nCommercial Area Is Very Near To Premises.\nTotal Covered Area Of House Is 4500 Sq Ft.\nDouble Story House.\nCall Us For More Details",
//     transaction_type: "Sell",
//     property_subtype: "Residential",
//     bedrooms: 5,
//     bathrooms: 6,
//     price: 97500000,
//     keywords: [
//       "family home",
//       "garden",
//       "residential",
//       "charming",
//       "sami furnished",
//     ],
//     additional_features: {
//       "Main Features": [
//         "Parking Spaces",
//         "Double Glazed Windows",
//         "Central Air Conditioning",
//         "Central Heating Flooring",
//         "Electricity Backup",
//         "Waste Disposal",
//         "Floors: 2",
//       ],
//       "Other Main Features": [
//         "Rooms",
//         "Bedrooms: 5",
//         "Bathrooms: 6",
//         "Drawing Room",
//         "Dining Room",
//       ],
//       "Business and Communication": [
//         "Broadband Internet Access",
//         "Satellite or Cable TV Ready",
//         "Intercom",
//         "Other Business and Communication Facilities",
//       ],
//       "Community Features": [
//         "Community Lawn or Garden",
//         "Community Swimming Pool",
//         "Community Gym",
//         "First Aid or Medical Centre",
//         "Day Care Centre",
//         "Kids Play Area",
//         "Barbeque Area",
//         "Mosque",
//         "Community Centre",
//         "Other Community Facilities",
//       ],
//       "Healthcare Recreational": [
//         "Lawn or Garden",
//         "Other Healthcare and Recreation Facilities",
//       ],
//       amenities: ["fireplace", "private garden", "garage"],
//       nearby_places: [
//         "schools",
//         "parks",
//         "grocery stores",
//         "hospital",
//         "restaurants",
//         "airport",
//         "shopping malls",
//       ],
//     },
//   },
// ];

// Create an async thunk to fetch data from the API
export const fetchData = createAsyncThunk("properties/fetchData", async () => {
  try {
    const response = await axios.get(
      "https://rebhub-web-server.vercel.app/admin/listing/getlistings"
    );
    console.log(response.data, "response.data");
    return response.data;
  } catch (error) {
    throw error;
  }
});

const propertySlice = createSlice({
  name: "properties",
  initialState: {
    data: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default propertySlice.reducer;
